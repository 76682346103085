var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.inviteSent
    ? _c(
        "v-card",
        [
          _c(
            "v-btn",
            {
              staticStyle: { position: "absolute", right: "0" },
              attrs: { icon: "", color: "black" },
              on: { click: _vm.close }
            },
            [_c("v-icon", [_vm._v("clear")])],
            1
          ),
          _vm.loading
            ? _c(
                "v-row",
                {
                  staticClass: "fill-height",
                  staticStyle: { "min-height": "575px" },
                  attrs: {
                    align: "center",
                    justify: "center",
                    "no-gutters": ""
                  }
                },
                [
                  _c("loading-symbol", {
                    attrs: {
                      size: 120,
                      color: _vm.$vuetify.theme.currentTheme.primary
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading"
                }
              ],
              staticClass: "pa-6"
            },
            [
              _c(
                "v-card-title",
                [
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c(
                      "p",
                      {
                        staticClass: "text-h5 text-center primary--text",
                        attrs: { id: "titleText" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.updatingExistingMember
                                ? _vm.$t("account.updateMember.title")
                                : _vm.$t("account.inviteMember.title")
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                            }
                          },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                top: "",
                                disabled: !_vm.updatingExistingMember,
                                color: "transparent"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b({}, "div", attrs, false),
                                            on
                                          ),
                                          [
                                            _c("v-text-field", {
                                              staticClass: "data-hj-suppress",
                                              attrs: {
                                                id: "emailField",
                                                label: _vm.$t(
                                                  "textFields.email"
                                                ),
                                                type: "email",
                                                filled: "",
                                                "prepend-inner-icon": "email",
                                                rules: [
                                                  _vm.rules.emailRequired(
                                                    _vm.$t(
                                                      "inputRules.emailExists"
                                                    )
                                                  ),
                                                  _vm.rules.emailValid(
                                                    _vm.$t(
                                                      "inputRules.emailFormat"
                                                    )
                                                  ),
                                                  _vm.rules.notCurrentUserEmail(
                                                    _vm.$t(
                                                      "inputRules.emailNotAllowed"
                                                    ),
                                                    _vm.userEmail
                                                  )
                                                ],
                                                disabled:
                                                  _vm.updatingExistingMember ||
                                                  _vm.loadingError
                                              },
                                              model: {
                                                value: _vm.newMember.email,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.newMember,
                                                    "email",
                                                    $$v
                                                  )
                                                },
                                                expression: "newMember.email"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2314280421
                              )
                            },
                            [
                              _c("vitrue-tooltip", {
                                attrs: {
                                  message: _vm.$t(
                                    "tooltips.cantEditEmailOfExistingMember"
                                  )
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-select", {
                            attrs: {
                              id: "roleField",
                              items: _vm.roles,
                              "item-text": "name",
                              "item-value": "value",
                              filled: "",
                              "prepend-inner-icon": "mdi-face-agent",
                              label: _vm.$t("textFields.role"),
                              rules: [
                                _vm.rules.roleRequired(
                                  _vm.$t("inputRules.roleRequired")
                                )
                              ],
                              disabled: _vm.loadingError
                            },
                            on: {
                              input: function($event) {
                                return _vm.updateRestrictedAdminFlag()
                              }
                            },
                            model: {
                              value: _vm.newMember.role,
                              callback: function($$v) {
                                _vm.$set(_vm.newMember, "role", $$v)
                              },
                              expression: "newMember.role"
                            }
                          }),
                          _vm.isCurrentRole("End User")
                            ? _c("v-combobox", {
                                attrs: {
                                  id: "endUserTags",
                                  "prepend-inner-icon": "mdi-tag-multiple",
                                  label: _vm.$t("tagDropDown.label"),
                                  items: _vm.activeTags,
                                  "item-text": "name",
                                  multiple: "",
                                  filled: "",
                                  "return-object": "",
                                  clearable: "",
                                  "persistent-hint": "",
                                  disabled: _vm.loadingError
                                },
                                model: {
                                  value: _vm.newMember.endUserTags,
                                  callback: function($$v) {
                                    _vm.$set(_vm.newMember, "endUserTags", $$v)
                                  },
                                  expression: "newMember.endUserTags"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isCurrentRole("End User"),
                                  expression: "!isCurrentRole('End User')"
                                }
                              ]
                            },
                            [
                              _c("tag-dropdown", {
                                attrs: {
                                  id: "teamMemberTags",
                                  default: _vm.updatingExistingMember
                                    ? _vm.selectedUser.tags
                                    : [],
                                  disableAndShowEverythingTag: _vm.isCurrentRole(
                                    "Super Admin"
                                  )
                                },
                                on: {
                                  finishedLoadingTags: function($event) {
                                    _vm.loading = false
                                  },
                                  tagLoadingError: _vm.showTagLoadingError
                                },
                                model: {
                                  value: _vm.newMember.teamMemberTags,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newMember,
                                      "teamMemberTags",
                                      $$v
                                    )
                                  },
                                  expression: "newMember.teamMemberTags"
                                }
                              }),
                              _c(
                                "p",
                                {
                                  staticClass: "body-2 mt-4",
                                  attrs: { id: "restrictedAdminText" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "account.team.restrictedAdminTitle"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("v-switch", {
                                staticClass: "text-caption font-weight-bold",
                                attrs: {
                                  id: "restrictedAdminSwitch",
                                  disabled:
                                    !_vm.isCurrentRole("Admin") ||
                                    _vm.loadingError,
                                  "hide-details": "",
                                  label: _vm.adminCanSeePersonalData
                                    ? _vm.$t("account.team.restrictedAdminOff")
                                    : _vm.$t("account.team.restrictedAdminOn")
                                },
                                model: {
                                  value: _vm.adminCanSeePersonalData,
                                  callback: function($$v) {
                                    _vm.adminCanSeePersonalData = $$v
                                  },
                                  expression: "adminCanSeePersonalData"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.errorMessage
                    ? _c(
                        "p",
                        { staticClass: "px-4 text-body-1 warning--text" },
                        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "pb-5",
                  attrs: { "no-gutters": "", justify: "center" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "actionButton",
                        color: "primary white--text",
                        "min-width": "200px",
                        rounded: "",
                        disabled: _vm.loadingError
                      },
                      on: {
                        click: function($event) {
                          return _vm.createTeamMemberAndSendInvitation()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.updatingExistingMember
                            ? _vm.$t("buttons.save")
                            : _vm.$t("buttons.add")
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-card",
        { staticClass: "pa-6" },
        [
          _c(
            "v-row",
            {
              staticClass: "pt-4",
              attrs: { justify: "center", "no-gutters": "" }
            },
            [
              _c("v-img", {
                attrs: {
                  src: require("@/assets/images/email_confirmation.png"),
                  "max-width": "200px"
                }
              })
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("v-col", { attrs: { cols: "8" } }, [
                _c("p", { staticClass: "text-h6 text-center" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.updatingExistingMember
                          ? _vm.$t("account.updateMember.successDescription")
                          : _vm.$t("account.inviteMember.successDescription")
                      ) +
                      " "
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "finishButton",
                        color: "primary",
                        large: "",
                        rounded: "",
                        width: "100%"
                      },
                      on: { click: _vm.close }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.finish")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }