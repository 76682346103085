<template>
  <v-tooltip top :disabled="!disableAndShowEverythingTag" color="transparent">
    <template v-slot:activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs">
        <v-autocomplete
          id="tagSelector"
          :label="$t('tagDropDown.label')"
          prepend-inner-icon="mdi-tag-multiple"
          :items="tags"
          item-text="name"
          v-model="filterTags"
          multiple
          filled
          return-object
          clearable
          persistent-hint
          :hint="$t('tagDropDown.hint')"
          :disabled="disableAndShowEverythingTag || loadingFailed"
        >
          <template v-slot:selection="{ item, index }">
            <span class="font-weight-regular" v-if="index < 1">{{
              getTagName(item)
            }}</span>
            <span v-if="index === 1" class="grey--text caption ml-1"
              >(+{{
                filterTags.length - 1 + $t("tagDropDown.multipleTagsText")
              }})</span
            >
          </template>
          <template v-slot:item="{ item }">
            {{ getTagName(item) }}
          </template>

          <template v-slot:no-data>
            <span class="ml-3 caption">{{ $t("tagDropDown.noData") }}</span>
          </template>
        </v-autocomplete>
      </div>
    </template>
    <vitrue-tooltip :message="$t('tooltips.cantChangeTagsForSuperAdmin')" />
  </v-tooltip>
</template>

<script>
import { getTeamTags } from "@/customApi";
import VitrueTooltip from "../common/VitrueTooltip.vue";

export default {
  name: "tag-dropdown",
  components: {
    VitrueTooltip
  },
  props: {
    default: Array,
    disableAndShowEverythingTag: Boolean
  },
  data: () => ({
    nothingTagName: "Nothing",
    everythingTagName: "Everything",
    tags: ["Nothing"],
    filterTags: [],
    hasEverythingTag: false,
    loadingFailed: false
  }),

  async mounted() {
    this.filterTags = this.default ? this.default : [];
    await this.getTags();
  },
  methods: {
    async getTags() {
      try {
        let dto = {
          everythingTag: true
        };
        let allTags = await getTeamTags(dto);

        let everythingTag = allTags.find(
          t => t.name.toLowerCase() == "everything"
        );

        this.hasEverythingTag = everythingTag != null;
        if (this.default.length == 0 && this.hasEverythingTag) {
          this.filterTags = [everythingTag];
        }

        // order so everything tag first, and nothing tag at end
        var tagsWithoutEverything = allTags.filter(
          t => t.name != everythingTag.name
        );

        var tagArrayToDisplay = [
          everythingTag,
          ...tagsWithoutEverything,
          this.nothingTagName
        ];

        this.tags = tagArrayToDisplay.filter(x => !x.expiredAt);
        this.$emit("finishedLoadingTags");
      } catch (error) {
        this.loadingFailed = true;
        this.$logger.captureException(error);
        this.$emit("tagLoadingError");
      }
    },
    getTagName(item) {
      if (item === this.nothingTagName || item.name === this.nothingTagName) {
        return this.$t("tagDropDown.nothingTag");
      }

      if (
        item === this.everythingTagName ||
        item.name === this.everythingTagName
      ) {
        return this.$t("tagDropDown.everythingTag");
      }

      return item.name ?? item;
    }
  },
  watch: {
    filterTags(newVal, oldVal) {
      // this code is only relevant if everything tag exists
      if (this.hasEverythingTag) {
        if (
          newVal.filter(e => e.name === "Everything").length > 0 &&
          oldVal.filter(e => e.name === "Everything").length == 0 &&
          newVal.length > 1
        ) {
          const index = newVal.findIndex(item => item.name === "Everything");
          this.filterTags = newVal.splice(index, 1);
          return;
        } else if (
          oldVal.filter(e => e.name === "Everything").length > 0 &&
          oldVal.length == 1 &&
          newVal.length > 1
        ) {
          const index = newVal.findIndex(item => item.name === "Everything");
          this.filterTags.splice(index, 1);
          return;
        }
      }

      if (this.filterTags.includes(this.nothingTagName)) {
        this.$emit("input", []);
      } else {
        this.$emit("input", this.filterTags);
      }

      // If filtertags is updated based on new value, return to prevent
      // calling getFilteredAnalytics twice (as watch will be triggered again)
      if (oldVal.includes(this.nothingTagName) && newVal.length > 1) {
        const index = this.filterTags.indexOf(this.nothingTagName);
        this.filterTags.splice(index, 1);
        return;
      } else if (
        !oldVal.includes(this.nothingTagName) &&
        newVal.includes(this.nothingTagName)
      ) {
        this.filterTags = [this.nothingTagName];
        return;
      } else if (newVal.length === 0) {
        this.filterTags = [this.nothingTagName];
        return;
      }
    },
    disableAndShowEverythingTag(newVal) {
      if (newVal) {
        let everythingTag = this.tags.find(
          t => t.name && t.name.toLowerCase() == "everything"
        );
        if (everythingTag) {
          this.filterTags = [everythingTag];
        }
      }
    }
  }
};
</script>
