var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: {
        top: "",
        disabled: !_vm.disableAndShowEverythingTag,
        color: "transparent"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "div",
                _vm._g(_vm._b({}, "div", attrs, false), on),
                [
                  _c("v-autocomplete", {
                    attrs: {
                      id: "tagSelector",
                      label: _vm.$t("tagDropDown.label"),
                      "prepend-inner-icon": "mdi-tag-multiple",
                      items: _vm.tags,
                      "item-text": "name",
                      multiple: "",
                      filled: "",
                      "return-object": "",
                      clearable: "",
                      "persistent-hint": "",
                      hint: _vm.$t("tagDropDown.hint"),
                      disabled:
                        _vm.disableAndShowEverythingTag || _vm.loadingFailed
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function(ref) {
                            var item = ref.item
                            var index = ref.index
                            return [
                              index < 1
                                ? _c(
                                    "span",
                                    { staticClass: "font-weight-regular" },
                                    [_vm._v(_vm._s(_vm.getTagName(item)))]
                                  )
                                : _vm._e(),
                              index === 1
                                ? _c(
                                    "span",
                                    { staticClass: "grey--text caption ml-1" },
                                    [
                                      _vm._v(
                                        "(+" +
                                          _vm._s(
                                            _vm.filterTags.length -
                                              1 +
                                              _vm.$t(
                                                "tagDropDown.multipleTagsText"
                                              )
                                          ) +
                                          ")"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(" " + _vm._s(_vm.getTagName(item)) + " ")
                            ]
                          }
                        },
                        {
                          key: "no-data",
                          fn: function() {
                            return [
                              _c("span", { staticClass: "ml-3 caption" }, [
                                _vm._v(_vm._s(_vm.$t("tagDropDown.noData")))
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.filterTags,
                      callback: function($$v) {
                        _vm.filterTags = $$v
                      },
                      expression: "filterTags"
                    }
                  })
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c("vitrue-tooltip", {
        attrs: { message: _vm.$t("tooltips.cantChangeTagsForSuperAdmin") }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }