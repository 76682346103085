<template>
  <v-card v-if="!inviteSent">
    <v-btn
      @click="close"
      style="position:absolute; right: 0; top 0"
      icon
      color="black"
    >
      <v-icon>clear</v-icon>
    </v-btn>
    <v-row
      v-if="loading"
      class="fill-height"
      align="center"
      justify="center"
      no-gutters
      style="min-height: 575px"
      ><loading-symbol
        :size="120"
        :color="$vuetify.theme.currentTheme.primary"
      />
    </v-row>

    <div class="pa-6" v-show="!loading">
      <v-card-title>
        <v-row justify="center">
          <p id="titleText" class="text-h5 text-center primary--text">
            {{
              updatingExistingMember
                ? $t("account.updateMember.title")
                : $t("account.inviteMember.title")
            }}
          </p>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" @submit.prevent>
            <v-tooltip
              top
              :disabled="!updatingExistingMember"
              color="transparent"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-text-field
                    class="data-hj-suppress"
                    id="emailField"
                    v-model="newMember.email"
                    :label="$t('textFields.email')"
                    type="email"
                    filled
                    prepend-inner-icon="email"
                    :rules="[
                      rules.emailRequired($t('inputRules.emailExists')),
                      rules.emailValid($t('inputRules.emailFormat')),
                      rules.notCurrentUserEmail(
                        $t('inputRules.emailNotAllowed'),
                        userEmail
                      )
                    ]"
                    :disabled="updatingExistingMember || loadingError"
                  />
                </div>
              </template>
              <vitrue-tooltip
                :message="$t('tooltips.cantEditEmailOfExistingMember')"
              />
            </v-tooltip>
            <v-select
              id="roleField"
              v-model="newMember.role"
              :items="roles"
              item-text="name"
              item-value="value"
              filled
              prepend-inner-icon="mdi-face-agent"
              :label="$t('textFields.role')"
              :rules="[rules.roleRequired($t('inputRules.roleRequired'))]"
              @input="updateRestrictedAdminFlag()"
              :disabled="loadingError"
            ></v-select>
            <v-combobox
              id="endUserTags"
              v-if="isCurrentRole('End User')"
              prepend-inner-icon="mdi-tag-multiple"
              :label="$t('tagDropDown.label')"
              :items="activeTags"
              item-text="name"
              v-model="newMember.endUserTags"
              multiple
              filled
              return-object
              clearable
              persistent-hint
              :disabled="loadingError"
            />
            <div v-show="!isCurrentRole('End User')">
              <tag-dropdown
                id="teamMemberTags"
                v-model="newMember.teamMemberTags"
                :default="updatingExistingMember ? selectedUser.tags : []"
                :disableAndShowEverythingTag="isCurrentRole('Super Admin')"
                @finishedLoadingTags="loading = false"
                @tagLoadingError="showTagLoadingError"
              />
              <p id="restrictedAdminText" class="body-2 mt-4">
                {{ $t("account.team.restrictedAdminTitle") }}
              </p>
              <v-switch
                id="restrictedAdminSwitch"
                v-model="adminCanSeePersonalData"
                :disabled="!isCurrentRole('Admin') || loadingError"
                hide-details
                :label="
                  adminCanSeePersonalData
                    ? $t('account.team.restrictedAdminOff')
                    : $t('account.team.restrictedAdminOn')
                "
                class="text-caption font-weight-bold"
              ></v-switch>
            </div>
          </v-form>
        </v-container>
        <p class="px-4 text-body-1 warning--text" v-if="errorMessage">
          {{ errorMessage }}
        </p>
      </v-card-text>

      <v-row class="pb-5" no-gutters justify="center">
        <v-btn
          id="actionButton"
          color="primary white--text"
          @click="createTeamMemberAndSendInvitation()"
          min-width="200px"
          rounded
          :disabled="loadingError"
          >{{
            updatingExistingMember ? $t("buttons.save") : $t("buttons.add")
          }}</v-btn
        >
      </v-row>
    </div>
  </v-card>
  <v-card v-else class="pa-6">
    <v-row justify="center" no-gutters class="pt-4">
      <v-img
        :src="require('@/assets/images/email_confirmation.png')"
        max-width="200px"
      />
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <p class="text-h6 text-center">
          {{
            updatingExistingMember
              ? $t("account.updateMember.successDescription")
              : $t("account.inviteMember.successDescription")
          }}
        </p>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="5">
        <v-btn
          id="finishButton"
          color="primary"
          large
          rounded
          width="100%"
          @click="close"
          >{{ $t("buttons.finish") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {
  createTeamMembers,
  updateUserPermissions,
  getTeamTags,
  addEndUsersAndUpdateTags
} from "../../customApi";
import { mapMutations, mapGetters } from "vuex";
import TagDropdown from "@/components/common/TagDropdown.vue";
import VitrueTooltip from "../common/VitrueTooltip.vue";
import LoadingSymbol from "@/components/common/loaders/LoadingSymbol.vue";

export default {
  components: {
    TagDropdown,
    VitrueTooltip,
    LoadingSymbol
  },
  props: {
    selectedUser: {
      default: null,
      type: Object
    }
  },
  data: () => ({
    loadingError: false,
    loading: true,
    inviteSent: false,
    valid: false,
    roles: [
      { name: "Super Admin", value: 10 },
      { name: "Admin", value: 0 },
      { name: "End User", value: 40 }
    ],
    restrictedAdminRoleValue: 20,
    newMember: {
      id: "",
      email: "",
      role: "",
      teamMemberTags: [],
      endUserTags: []
    },
    defaultItem: {
      email: "",
      role: "",
      teamMemberTags: [],
      endUserTags: []
    },
    errorMessage: "",
    rules: {
      emailRequired(m) {
        return v => !!v || m;
      },
      emailValid(m) {
        return v => /.+@.+\..+/.test(v) || m;
      },
      notCurrentUserEmail(m, currentEmail) {
        return v => v !== currentEmail || m;
      },
      roleRequired(m) {
        return v => v !== "" || m;
      }
    },
    adminCanSeePersonalData: true,
    userTags: []
  }),
  async mounted() {
    if (this.updatingExistingMember) {
      this.newMember.email = this.selectedUser.email.value;
      this.newMember.id = this.selectedUser.id;

      if (this.selectedUser.role == 40) {
        this.newMember.endUserTags = this.selectedUser.tags;
      } else {
        this.newMember.teamMemberTags = this.selectedUser.tags;
      }

      if (this.selectedUser.role == 20) {
        this.newMember.role = this.roles.find(a => a.name === "Admin")?.value;
        this.adminCanSeePersonalData = false;
      } else {
        this.newMember.role = this.roles.find(
          a => a.value === this.selectedUser.role
        )?.value;
      }
    }
    let dto = {
      everythingTag: false
    };
    this.userTags = await getTeamTags(dto);
  },
  computed: {
    ...mapGetters(["ssoSignIn", "userEmail"]),
    updatingExistingMember() {
      return !!this.selectedUser;
    },
    teamMemberRole() {
      if (!this.adminCanSeePersonalData) {
        return this.restrictedAdminRoleValue;
      }
      return this.newMember.role;
    },
    activeTags() {
      return this.userTags.filter(x => x.expiredAt === null);
    }
  },
  methods: {
    ...mapMutations(["addAsyncRequest", "removeAsyncRequest"]),
    close() {
      this.inviteSent = false;
      this.$refs.form?.resetValidation();
      this.resetError();
      this.newMember = Object.assign({}, this.defaultItem);
      this.$emit("input", false);
    },
    resetError() {
      this.errorMessage = "";
    },
    async createTeamMemberAndSendInvitation() {
      this.resetError();
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      try {
        this.addAsyncRequest();

        if (this.updatingExistingMember) {
          let userDto = {
            id: this.selectedUser.id,
            userName: this.newMember.email,
            email: { value: String(this.newMember.email) },
            tags: this.isCurrentRole("End User")
              ? this.newMember.endUserTags
              : this.newMember.teamMemberTags,
            role: this.teamMemberRole,
            previousRole: this.selectedUser.role
          };

          await updateUserPermissions([userDto]);
        } else {
          if (this.isCurrentRole("End User")) {
            let endUser = {
              email: { value: String(this.newMember.email) },
              tags: this.newMember.endUserTags,
              autoJoinTeam: true
            };
            await addEndUsersAndUpdateTags([endUser]);
          } else {
            let teamMember = {
              userName: this.newMember.email,
              email: { value: String(this.newMember.email) },
              tags: this.newMember.teamMemberTags,
              role: this.teamMemberRole,
              inviteMember: !this.ssoSignIn
            };
            await createTeamMembers([teamMember]);
          }
        }

        this.removeAsyncRequest();
        this.inviteSent = true;
        this.$emit("update");
      } catch (error) {
        this.errorMessage = this.updatingExistingMember
          ? this.$t("account.updateMember.createError")
          : this.$t("account.inviteMember.createError");
        this.removeAsyncRequest();
      }
    },
    updateRestrictedAdminFlag() {
      if (!this.isCurrentRole("Admin")) {
        this.adminCanSeePersonalData = true;
      }
    },
    isCurrentRole(roleToCheck) {
      const roleName = this.roles.find(
        a => a.value == this.newMember.role
      )?.name;
      return roleName === roleToCheck;
    },
    showTagLoadingError() {
      this.loading = false;
      this.loadingError = true;
      this.errorMessage = this.$t("account.inviteMember.loadingTagError");
    }
  }
};
</script>
